import React, {useEffect} from 'react';
import './App.css';
import BreadThreads from "./components/BreadThreads";
import {Link, redirect, Route, Routes, useLocation} from "react-router-dom";
import Contact from "./components/Contact";
import Welcome from "./components/Welcome";
// @ts-ignore
import {MapContainer, Marker, Popup, TileLayer, useMap} from 'react-leaflet'
import {useRecoilState, useRecoilValue} from "recoil";
import {adminAtom, animAtom, zoomedAtom} from "./atoms";
import crea from "./components/creation.jpeg";
import stage from "./components/stage.jpg";
import locationim from "./components/location.jpeg";
import marche from "./components/marche.jpeg";
import Creations from "./components/Creations";
import Internship from "./components/Internship";
import Rent from "./components/Rent";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Facebook from "./components/social/Facebook";
import Instagram from "./components/social/Instagram";
import L from 'leaflet';

const market = new L.Icon({
    iconUrl: "/market.png",
    iconRetinaUrl: "/market.png",
    popupAnchor:  [-0, -0],
    iconSize: [55,55],
});

let routes = {
    accueil: {route: '/'}
}
const Redirect = ({path}: { path: string }) => {
    redirect(path)
    return <></>
}

class FindMe extends React.Component {
    render() {
        return <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>

            <div className="TitleCreation">
                Mon atelier de vannerie est situé à Coise (73800), entre Chambéry, Albertville et Grenoble. Située en
                Savoie, je me rends régulièrement dans la vallée du Grésivaudan en Isère.

            </div>
            <div>
                <p>Pour les marchés :</p>
                {/*<p><u>1er décembre</u> : marché de Noël des Marchés de la Combe de Savoie à St Pierre de Soucy (73 800)</p>*/}
                {/*<p><u>7 décembre</u> : marché de Noël de Barraux (38 530)</p>*/}
                <p><u>14 décembre</u> : marché de Noël de Cruet (73 800)</p>
            </div>
            <MapContainer style={{height: "500px", width: "100%"}} center={[45.5264502728123, 6.142430253100444]}
                          zoom={13} scrollWheelZoom={false}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker title={"Mon atelier"} position={[45.5264502728123, 6.142430253100444]}/>
                <Marker icon={market} title={"Marché de Noël de Cruet (73 800) le 14 décembre"} position={[45.527486437873854, 6.091351270538458]}/>
                {/*<Marker icon={market} title={"Marché de Noël de Barraux (38 530) le 7 décembre"} position={[45.435180964384145, 5.97900201804499]}/>*/}
                {/*<Marker icon={market} title={"Marché de Noël des Marchés de la Combe de Savoie à St Pierre de Soucy (73 800) le 1 décembre"} position={[45.50791908116655, 6.106091282001623]}/>*/}
            </MapContainer>
        </div>;
    }
}

function App() {
    const location = useLocation();
    const { hash, pathname, search } = location;
    let pag = pathname.split('/')?.[1]
    const adm =useRecoilValue(adminAtom)
    const [anim,setAnim] =useRecoilState(animAtom)
    const [zooma,setZoom] =useRecoilState(zoomedAtom)

    // Automatically scrolls to top whenever pathname changes
    useEffect(() => {
        window.scrollTo(0, 0);
        if (!anim){
            setAnim(true)
        }
    }, [pathname]);

    return (
        <div className="App">

            <div className="Logo">
                {/*  <svg style={{position: 'absolute'}} width={150} height={150}>
                            <circle shapeRendering="geometricPrecision" fill="none" r={65} cx={72} cy={72}
                                    strokeWidth={0.3}
                                    stroke="var(--police)"/>
                            <circle shapeRendering="geometricPrecision" fill="none" r={70} cx={75} cy={75}
                                    strokeWidth={0.3}
                                    stroke="var(--police)"/>
                        </svg>*/}
                <img style={{ width: '250px', margin: '5px'}} src='/logo-bea.png'/>
            </div>
            <div className="Menu">
                <Link className={pag===''?'active':''} to={adm + ""}>Accueil</Link>
                <Link className={pag==='creations'?'active':''} to={adm + "/creations"}>Créations</Link>
                <Link className={pag==='stages'?'active':''} to={adm + "/stages"}>Ateliers/Stages</Link>
                {/*<Link className={pag==='locations'?'active':''} to={adm + "/locations"}>Locations</Link>*/}
                <Link className={pag==='ou-me-trouver'?'active':''}  to={adm + "/ou-me-trouver"}>Où me trouver</Link>
                <Link className={pag==='contact'?'active':''} to={adm + "/contact"}>Contact</Link>
                <Link className={pag==='actualites'?'active':''} to={adm + "/actualites"}>Actualités</Link>

            </div>
            <div className="Title">ATELIER DE VANNERIE </div>
            <div className="Content">
                {zooma!=''?<div onClick={()=>setZoom('')} className={"Fullscreen"}><img style={{background:'url(/placeholder.png) no-repeat center'}} src={zooma} /> <div className={'FullClose'}>✖</div></div>:''}
                <Routes>
                    <Route path="" element={<><Welcome/></>}/>
                    <Route path="/creations" element={<><Creations/></>}/>
                    <Route path="/stages" element={<Internship/>}/>
                    <Route path="/locations" />
                    <Route path="/contact" element={<Contact/>}/>
                    <Route path="/ou-me-trouver" element={
                        <FindMe/>}/>
                    <Route path="/login" element={
                        <div className='Login'>
                            <input type="text"/>
                            <input type="password"/>
                        </div>}/>
                    <Route path="/actualites" element={
                        <iframe data-w-type="embedded"
                                 // frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"
                                src="https://s2635.mjt.lu/wgt/s2635/xmpk/form?c=078da294" width="100%"
                                style={{margin :0 ,border:"none"
                                    ,minHeight: 'calc(100vh - 115px)'}}
                        ></iframe>}/>
                </Routes>
            </div>
            <footer className='Footer'>
                 <div className="FooterMedia" style={{margin: '15px 0'}}><span></span>  <span style={{display:'flex',alignItems:'center'}}><span>Mes réseaux :</span> <Facebook/> <Instagram/> </span></div>
                <div className="LinkContainer">
                    <Link to={"/creations"}>
                        <div className="LinkItem">
                            <div style={{
                                borderRadius: '250px',
                                width: '250px',
                                height: '250px',
                                backgroundSize: '350px',
                                border: '4px solid #5c6f66',
                                backgroundImage: `url(${crea})`,
                                backgroundPosition: '-52px -10px'
                            }}/>
                            <p>Créations</p>
                        </div>
                    </Link> <Link to={"/stages"}>
                    <div className="LinkItem">

                        <div style={{
                            borderRadius: '250px',
                            width: '250px',
                            height: '250px',
                            backgroundSize: '370px',
                            border: '4px solid #5c6f66',
                            backgroundImage: `url(${stage})`,
                            backgroundPosition: ''
                        }}/>
                        <p>Ateliers/Stages</p>
                    </div>
                </Link>
{/*                    <Link to={"/locations"}>
                    <div className="LinkItem">

                        <div style={{
                            borderRadius: '250px',
                            width: '250px',
                            height: '250px',
                            backgroundSize: '240px',
                            border: '4px solid #5c6f66',
                            backgroundImage: `url(${locationim})`,
                            backgroundPosition: '5px 15px'
                        }}/>
                        <p>Locations</p>
                    </div>
                </Link>*/}
                    <Link to={"/ou-me-trouver"}>
                    <div className="LinkItem">

                        <div style={{
                            borderRadius: '250px',
                            width: '250px',
                            height: '250px',
                            backgroundSize: '250px',
                            border: '4px solid #5c6f66',
                            backgroundImage: `url(${marche})`,
                            backgroundPosition: '0px 0px'
                        }}/>
                        <p>Où me trouver</p>
                    </div>
                </Link>

                </div>
                <div  className="Text">
                    Mon atelier de vannerie est situé à Coise (73800), entre Chambéry, Albertville et Grenoble. Située
                    en
                    Savoie, je me rends régulièrement dans la vallée du Grésivaudan en Isère. Vous pouvez trouver les
                    marchés où je suis présente en suivant ce lien <Link to="/ou-me-trouver" style={{fontSize: '1em'}}>“Où
                    me trouver”.</Link>
                </div>
            </footer>
            <div style={{width: '100%', height: '35px'}}>
                2024 <a style={{color: 'var(--police)'}}
                        href={"mailto:web@farandole-panier.fr"}>web@farandole-paniers.fr</a>
            </div>
            <BreadThreads/>
        </div>
    );
}

export default App;