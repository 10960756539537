import React, {ReactNode} from 'react';
import './Creations.css'
import {Link} from "react-router-dom";

import {Carousel} from "react-responsive-carousel";
import {useRecoilState} from "recoil";
import {zoomedAtom} from "../atoms";

const CreationElement = ({
                             images,
                             title,
                             description,
                             price
                         }: {
    images: { url: string, title: string }[],
    title: string,
    description: ReactNode,
    price: string

}) => {
    const [, setZoomed] = useRecoilState(zoomedAtom);
    return <div className="CreationElement">
        <div className="CarouselWrap">
            <Carousel
                infiniteLoop={true}
                autoPlay={true}
                className="CreationImage" statusFormatter={() => ''}
                transitionTime={1000}
                onClickItem={(i: number) => {
                    setZoomed(images[i].url)
                }}
                renderThumbs={() => []}>
                {images.map((e: { url: string, title: string }) => <div key={e.title}>
                    <img alt={e.title} src={e.url}/>
                </div>)}

            </Carousel>
        </div>
        <div className="CreationText">
            <p className="CreationTitle">{title}</p>
            <p>{description}</p>
            <p>{price}</p>
        </div>
    </div>;

}

function Creations() {

    // @ts-ignore
    return (
        <div className="Creations">
            {/*<Créations>*/}
            <div className="TitleCreation">
                Vous trouverez ci-dessous les modèles habituels que je propose ainsi qu'un ordre d'idée de prix. Pour
                plus d'informations n'hésitez pas à me <Link to="/contact">contacter</Link>
                <br/>
                <br/>
                <br/>
            </div>

            <div className="CreationContainer">
                <CreationElement
                    images={[
                        {url: '/paniere_rec_1.jpeg', title: 'paniere rectangulaire 1'},
                        {url: '/paniere_rec_2.jpeg', title: 'paniere  rectangulaire 2'},
                    ]}
                    title={'PANIER RECTANGULAIRE'
                    }
                    description={`Panier rectangulaire
                    Sa forme vous permet de déposer, vos légumes du potager ou encore vos champignons, sans les écraser.
                    Un modèle moins répandu, et pourtant très utile !`}
                    price={'A partir de 75 euros'}
                />
                <CreationElement
                    images={[
                        {url: '/paniere_rect_1.jpeg', title: 'paniere rectangulaire'},
                        {url: '/paniere_rect_2.jpeg', title: 'paniere rectangulaire'},
                    ]}
                    title={'PANIÈRE À LINGE RECTANGULAIRE'
                    }
                    description={'Panière ajourée avec fond plein, en osier blanc. D\'environ 60 cm x 40 cm.'}
                    price={'A partir de 130 euros'}
                />
                <CreationElement
                    images={[
                        {url: '/paniere_ronde.jpeg', title: 'paniere ronde'},
                    ]}
                    title={'PANIER ROND EN ÉCLISSE'
                    }
                    description={'Panière ajourée avec un fond ajouré, en osier blanc. D\'environ 60 cm x 40 cm.'}
                    price={'A partir de 120 euros'}
                />
                <CreationElement
                    images={[
                        {url: '/bombonne_1.jpg', title: 'bombonne1'},
                        {url: '/bombonne_2.jpg', title: 'bombonne2'},
                        {url: '/bombonne_3.jpg', title: 'bombonne3'}
                    ]}
                    title={'Habillage de bonbonne/dame-jeanne en osier.'
                    }
                    description={'Il permet de bien protéger leur verre contre les chocs et ainsi de les faire perdurer. Les deux poignées permettent de les transporter plus facilement.'}
                    price={'Pour exemple, l’habillage d’une bonbonne de 15L s’élève à 90 euros'}
                /><CreationElement
                images={[
                    {url: '/corbeille1.jpg', title: 'Corbeille1'},
                    {url: '/corbeille2.jpg', title: 'Corbeille2'},
                    {url: '/corbeille3.jpg', title: 'Corbeille2'},
                    {url: '/corbeille4.jpg', title: 'Corbeille3'}
                ]}
                title={'Corbeille grande taille avec double anse.'
                }
                description={'Modèle d’environ 50 cm de diamètre en haut, pour 20 cm de hauteur. Cette corbeille est polyvalente et peut servir pour du rangement, du linge ou encore au potager.'}
                price={'A partir de 79 euros'}
            />
                <CreationElement
                    images={[
                        {url: '/panierfraises_1.jpg', title: 'panierfraises_11'},
                        {url: '/panierfraises_2.jpg', title: 'panierfraises_12'},
                    ]}
                    title={'Panier à fraises.'
                    }
                    description={'Travail ajouré, en brut et/ou blanc. Ce tressage permet d’obtenir un panier élégant et très léger.'}
                    price={'A partir de 65 euros'}
                />
                <CreationElement
                    images={[
                        {url: '/plein1.jpg', title: 'plein1'},
                        {url: '/plein2.jpg', title: 'plein2'},
                    ]}
                    title={'Panier ovale en osier plein.'
                    }
                    description={<>De grande taille pour faire les courses ou encore aller glaner les champignons, le
                        “crocane” est un incontournable.<br/>
                        De taille plus modeste, le “panier à beurre” est un passe-partout, plus léger et offrant déjà
                        une belle contenance.</>}
                    price={'A partir de 69 euros'}
                /><CreationElement
                images={[
                    {url: '/ecli1.jpg', title: 'ecli1'},
                    {url: '/ecli2.jpg', title: 'ecli2'},
                    {url: '/ecli3.jpg', title: 'ecli3'},
                    {url: '/ecli4.jpg', title: 'ecli4'},
                ]}
                title={'Panier ovale en éclisses.'
                }
                description={'De petite à grande taille, droit ou évasé, ce panier en éclisses reste léger et peut être tressé avec une seule variété d’osiers ou plusieurs, selon vos envies.'}
                price={'A partir de 65 euros'}
            /><CreationElement
                images={[
                    {url: '/caba1.jpg', title: 'caba1'},
                    {url: '/caba2.jpg', title: 'caba2'}
                ]}
                title={'Sac cabas en éclisses.'
                }
                description={'Avec ses poignées en cuir, ce cabas allie légèreté et élégance, il vous permet de transporter des courses ou vos affaires du quotidien. Tout dépend de l’usage que vous lui réserverez !'}
                price={'A partir de 75 euros'}
            /><CreationElement
                images={[
                    {url: '/distributeur1.jpg', title: 'caba1'},
                    {url: '/distributeur2.jpg', title: 'caba2'},
                    {url: '/distributeur3.jpg', title: 'caba2'}
                ]}
                title={' Distributeur de pommes de terre'
                }
                description={"En grand format, il vous permettra de ranger vos pommes de terres à l'abri de la lumière. De plus petite taille, il vous permettra également de stocker à portée de main vos noix, noisettes, oignons, aulx ..."}
                price={'A partir de 45 euros'}
            />
                <CreationElement
                    images={[
                        {url: '/charmotte_1.jpg', title: 'charmotte1'},
                        {url: '/charmotte_2.jpg', title: 'charmotte2'},
                        {url: '/charmotte_3.jpg', title: 'charmotte3'},
                        {url: '/charmotte_6.jpg', title: 'charmotte6'},
                        {url: '/charmotte_7.jpg', title: 'charmotte7'},
                        {url: '/charmotte_4.jpg', title: 'charmotte4'},
                        {url: '/charmotte_5.jpg', title: 'charmotte5'}
                    ]}
                    title={'CHARMOTTE'
                    }
                    description={'Panier de cueillette à bandoulière qui vous permettra de glaner des champignons, des plantes sauvages, des châtaignes ou autre ...'}
                    price={'A partir de 65 euros'}
                />
                {/*<CreationElement
                    images={[
                        {url: '/sac_med_1.jpg', title: 'sac_med_1'},
                        {url: '/sac_med_2.jpg', title: 'sac_med_2'},
                    ]}
                    title={'SAC EN TRESSE MÉDITERRANÉENNE'
                    }
                    description={'Sac à main avec ou sans tissu pour l’habillage intérieur, en tresse méditerranéenne (point néolithique ou du Burkina)'}
                    price={'De 50 à 70 euros'}
                />*/}
                <CreationElement
                images={[
                    {url: '/corbeille_ronde_1.jpg', title: 'corbeille_ronde1'},
                    {url: '/corbeille_ronde_2.jpg', title: 'corbeille_ronde2'},
                    {url: '/corbeille_ronde_3.jpg', title: 'corbeille_ronde3'}
                ]}
                title={'CORBEILLE RONDE AVEC POIGNÉES'
                }
                description={'Corbeille traditionnelle ronde avec poignées.'}
                price={'A partir de 35 euros'}
            /> <CreationElement
                images={[
                    {url: '/helico_1.jpg', title: 'helico1'},
                    {url: '/helico_2.jpg', title: 'helico2'},
                    {url: '/helico_3.jpg', title: 'helico3'}
                ]}
                title={'PANIER HÉLICOÏDAL/ASYMÉTRIQUE '
                }
                description={'Panier hélicoïdal, aussi appelé panier asymétrique, tressé en osier et surmonté d’une anse en bois.'}
                price={'A partir de 65 euros'}
            /><CreationElement
                images={[
                    {url: '/suspension_1.jpg', title: 'suspension1'},
                    {url: '/suspension_2.jpg', title: 'suspension2'},
                ]}
                title={'SUSPENSION VÉGÉTALE'
                }
                description={'Suspension végétale en clématite sauvage ecorcée et cornouiller sanguin sauvages (selon saison) tressés en aléatoire.'}
                price={'A partir de 28 euros'}
            /><CreationElement
                images={[
                    {url: '/pan_rond1.jpeg', title: 'pan_rond1'},
                    {url: '/pan_rond2.jpeg', title: 'pan_rond2'},
                    {url: '/pan_rond3.jpeg', title: 'pan_rond3'},
                ]}
                title={'PANIER ROND EN ÉCLISSE'
                }
                description={'Panier rond en éclisses. Bordure roulée et anse cordée.'}
                price={'A partir de 65 euros'}
            />

                {/*   <div className="CreationElement">

                    <div className="CreationImage">
                        <img className="CreationImage" src={crea2}/>
                    </div>
SAC EN TRESSE MÉDITERRANÉENNE

Sac à main avec ou sans tissu pour l’habillage intérieur, en tresse méditerranéenne (point néolithique ou du Burkina)

De 50 à 70 euros








                    <div className="CreationText">
                        <p className="CreationTitle">"Les Bouyricous"</p>
                        <p> paniers traditionnels périgourdins
                            selon la taille
                        </p>
                        <p>de 35 € à 90 €</p>
                    </div>
                </div>
                <div className="CreationElement">

                    <div className="CreationImage">
                        <img className="CreationImage" src={crea2}/>
                    </div>

                    <div className="CreationText">
                        <p className="CreationTitle">"Les Bouyricous"</p>
                        <p>paniers traditionnels périgourdins
                            selon la taille</p>
                        <p>de 35 € à 90 €</p>
                    </div>
                </div>*/}

            </div>


        </div>
    );
}

export default Creations;
